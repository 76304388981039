import React from 'react'





const Footer = () => {
    return (

        <footer class="">

          


        </footer>





    )
}

export default Footer