import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const SendNotification = () => {

    const backUrl = process.env.REACT_APP_BACK_URL
    const navigate = useNavigate();

    const [notData, setNotData] = useState({
        title: '',
        body: '',
        // imageUrl: '',
    });

    const handleChange = (name, value) => {
        setNotData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const Submit = (e) => {
        e.preventDefault();
        if (!notData.title || !notData.body) {
            alert('Please fill in all fields before submitting.');
            return;
        }

        console.log(notData, "sendd")





        axios.post(`${backUrl}/sendnotification`, notData, {
            // headers: {
            //     "Content-Type": "multipart/form-data",
            // }
        })
            .then((res) => {
                console.log(res)
                // alert(res.data)
                window.location.reload()
            })
    };


    return (
        <>
            <form class="max-w-sm mx-auto border rounded-lg">

                <p className='text-center relative mb-4 px-5 py-2.5 rounded-t-lg font-medium bg-blue-950 text-white'>Send Your Notification  <img src='./active.png' className='size-10 absolute top-0 right-0' /></p>


                <div className='p-4'>
                    <div class="mb-6">
                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Notification Title</label>
                        <input type="text" id="title" onChange={(e) => handleChange(e.target.name, e.target.value)} value={notData.title} name='title' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                    </div>
                    <div class="mb-12">
                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Notification Body</label>
                        <input type="text" id="body" name='body' onChange={(e) => handleChange(e.target.name, e.target.value)} value={notData.body} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                    </div>

                    <button onClick={Submit} type="submit" class="text-white mb-12 bg-blue-950 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                </div>
            </form></>

    )
}

export default SendNotification