import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

const PollList = () => {

    const [data, setData] = useState([])
    const backUrl = process.env.REACT_APP_BACK_URL;
    const [showPopup, setShowPopup] = useState(false);
    const [selectedPId, setSelectedPId] = useState(null);




    useEffect(() => {
        axios.get(`${backUrl}/admin/getpolls`)
            .then((res) => {
                console.log(res, "list of news")
                // const result = [...res.data]
                if (Array.isArray(res.data)) {
                    setData(res.data.reverse());  // Only reverse if it's an array
                } else {
                    console.error('res.data is not an array');
                    setData([]);  // Optionally set an empty array or handle error
                }
            })
    }, [])

    const handleConfirmDelete = () => {
        axios.post(`${backUrl}/admin/delpoll`, { pollid: selectedPId })
            .then((res) => {
                console.log(res, "fhgjkuilo;p")

                setShowPopup(false);
                setSelectedPId(null);
                // alert(res.data.msg);
                window.location.reload();
                // setData(data.filter((item) => item.id !== selectedPId));

            })
    }

    const handleCancelDelete = () => {
        setShowPopup(false);
        setSelectedPId(null);
    };

    const handleDeleteClick = (PollId) => {
        setSelectedPId(PollId);
        setShowPopup(true);
    };

    return (
        <div className='mt-4'>
            <p className='text-md font-medium'>
                All Polls
            </p>


            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">No.</th>

                            <th scope="col" className="px-6 py-3">Poll ID</th>
                            <th scope="col" className="px-6 py-3">Poll Match Title</th>
                            <th scope="col" className="px-6 py-3">Delete</th>
                            <th scope="col" className="px-6 py-3"></th>
                            {/* <th scope="col" className="px-6 py-3"></th> */}
                            {/* <th scope="col" className="px-6 py-3"></th> */}

                        </tr>
                    </thead>

                    <tbody>

                        {data.map((item, index) => (
                            item ? (
                                <tr key={index} className="bg-white text-blue-950 border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td className="px-6 py-4  ">{index + 1}</td>

                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.PollId}</th>

                                    <td className="px-6 py-4 line-clamp-2">{item.question}</td>
                                    {/* <td className="px-6 py-4">Add</td> */}
                                    {/* <td className="px-6 py-4">Remove</td> */}
                                    <td className="px-6 py-4 text-xl  text-red-500"> <button onClick={() => handleDeleteClick(item.PollId)}>
                                        <MdDelete />
                                    </button></td>

                                    <td className="px-6 py-4 text-xl hidden "><Link to='/add-post'><FaEdit /></Link></td>

                                </tr>

                            ) : ""
                        ))}


                    </tbody>
                </table>

                {showPopup && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-6 rounded shadow-lg text-center">
                            <h2 className="text-lg mb-4">
                                Are you sure you want to delete this poll item?
                            </h2>
                            <p className="mb-4">Poll ID: {selectedPId}</p>
                            <div className="flex justify-center gap-4">
                                <button
                                    onClick={handleConfirmDelete}
                                    className="px-4 py-2 bg-red-500 text-white rounded"
                                >
                                    Yes, Delete
                                </button>
                                <button
                                    onClick={handleCancelDelete}
                                    className="px-4 py-2 bg-gray-300 text-black rounded"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
}

export default PollList