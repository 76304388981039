import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import axios from "axios"

import { useNavigate, useParams } from "react-router-dom"

const EditNews = () => {
    const backUrl = process.env.REACT_APP_BACK_URL
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate()

    const [news, setNews] = useState(
        {
            headline: '',
            text: '',
            title: "",
            permalink: "",
            description: "",
            category:"",

        }
    );


    // Custom toolbar options
    const modules = {
        toolbar: [
            [{ header: [0, false] }], // Heading options
            ['bold', 'italic', 'underline'], // Text formatting
            [{ list: 'ordered' }, { list: 'bullet' }], // List formatting
            [{ script: 'sub' }, { script: 'super' }], // Subscript/Superscript
            [{ indent: '-1' }, { indent: '+1' }], // Indentation
            [{ direction: 'rtl' }], // Text direction
            // [{ size: ['small', false, 'large', 'huge'] }], // Font size
            [{ color: [] }, { background: [] }], // Text color & background
            [{ align: [] }], // Alignment
            ['clean'] // Remove formatting
        ],
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log('File selected:', file); // For debugging, shows file details in console
        }
    };

    const handleChange = (name, value) => {
        setNews((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // console.log(news,"DFghgfds")



    const submitNews = (e) => {
        e.preventDefault();

        if (!news.headline.trim()) {
            alert('Please fill in the field before submitting.');
            return;
        }

        if (!news.text.trim()) {
            alert('Please fill in the  field before submitting.');
            return;
        }

        // if (!selectedFile) {
        //     alert('Please fill in all fields before submitting.');
        //     return;
        // }

        // console.log(selectedFile,"can i got");


        const formData = new FormData();
        formData.append("newsid", newsid);
        formData.append("headline", news.headline);
        formData.append("text", news.text);
        formData.append("permalink", news.permalink);
        formData.append("description", news.description);
        formData.append("category", news.category);
        if (selectedFile) {
            formData.append("selectedFile", selectedFile);
        }

        axios.put(`${backUrl}/admin/updatenews`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
                console.log(res);
                alert("News updated successfully");
                navigate(-1); // Navigate back to the previous page
            })
            .catch((error) => {
                console.error("Error updating news:", error);
                alert("Failed to update news. Please try again.");
            });







    };

    const newsid = useParams().id

    // console.log(newsid)


    useEffect(() => {

        axios.post(`${backUrl}/admin/newsinfo`, { newsid })
            .then((res) => {
                const ndata = res.data;
                console.log(res.data.selectedFile,"njioiuhg")
                setNews({
                    selectedFile:ndata.selectedFile, 
                    headline: ndata.headline || "",
                    text: ndata.text || "",
                    title: ndata.title || "",
                    permalink: ndata.permalink || "",
                    description: ndata.description || "",
                    category: ndata.category || "",

                });
            })
            .catch((error) => {
                console.error("Error fetching news info:", error);
                alert("Failed to fetch news details.");
            });


    }, [])


    useEffect(() => {
        const Email = localStorage.getItem('Email');  // get name of cookies
        console.log(Email, "email recieved from localstorage");
        if (Email == null) {
            // console.log("No email found")
            navigate('/')

        }

    }, [])



    return (

        <div className='gap-4 grid mt-5  min-w-72 w-full items-center justify-center'>
            <p className='text-lg font-medium text-center text-blue-950'>Edit News</p>


            <div>


                <p className='text-lg font-medium text-blue-950'>Edit  news banner</p>


                <div className=" items-center w-full">
                    <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed cursor-pointer dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">Click to upload</span> or drag and drop
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                SVG, PNG, JPG or GIF (MAX. 800x400px)
                            </p>
                        </div>
                        <input
                            id="dropzone-file"
                            type="file"
                            name="selectedFile"

                            className="hidden"
                            onChange={handleFileChange}
                        />
                    </label>

                    <div className='text-sm text-red-500 font-mono' >
                        This is current Image shows there if you want to change add new image <br/> (*if you don't want then leave as default*)
                        <img className='size-24' src={`${backUrl}/${news.selectedFile}`}/>
                    </div>

                    {selectedFile && (
                        <div className="mt-4 font-semibold text-sm text-gray-700">
                            <p>File name: {selectedFile.name}</p>
                            <p>File size: {(selectedFile.size / 1024).toFixed(2)} KB</p>
                        </div>
                    )}
                </div>

            </div>

            <div>
                <p className='text-lg font-medium text-blue-950'>Edit  news head line</p>
                <ReactQuill
                    className='w-full rounded-lg'
                    id="headline"
                    name="headline"
                    value={news.headline}
                    onChange={(value) => handleChange('headline', value)}
                    modules={modules}
                    placeholder="headline..." />
            </div>

            <div>
                <p className='text-lg font-medium text-blue-950'>Edit  news</p>
                <ReactQuill
                    className='w-full rounded-lg'
                    id='text'
                    name="text"
                    value={news.text}
                    onChange={(value) => handleChange('text', value)}
                    modules={modules}
                    placeholder="news..." />
            </div>

            <div className=''>
                <p className='text-lg font-medium text-blue-950'>Give Category</p>


                <form class="">
                    <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label>
                    <select
                        id="category"
                        name="category"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={news.category}
                        onChange={(e) => handleChange('category', e.target.value)} // Handle change here
                    >
                        <option value="">Select</option>
                        <option value="News">News</option>
                        <option value="Latest News">Latest News</option>
                        <option value="Spotlight">Spotlight</option>
                        <option value="Opinions">Opinions</option>
                        <option value="Special">Special</option>
                        <option value="Stats">Stats</option>
                        <option value="Interviews">Interviews</option>
                        <option value="Live Blogs">Live Blogs</option>
                    </select>
                </form>

            </div>


            <p className='text-lg font-medium text-blue-950'>Edit SEO here </p>



            <div className="mb-4 py-4 px-4 hidden rounded-md bg-gray-100">
                <label className="block text-lg font-medium text-gray-700" for="title">Title</label>
                <input id="title" type="text" name='title' value={news.title} onChange={(e) => handleChange(e.target.name, e.target.value)}
                    className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
                <span className='text-sm text-gray-500'>This is what will appear in the first line when this post shows up in the search results.</span>
            </div>

            <div className="mb-4  py-4 px-4 bg-gray-100 rounded-md">
                <label className="block text-lg font-medium text-gray-700" for="permalink">Permalink</label>
                <input type="text" id='permalink' name='permalink' value={news.permalink} onChange={(e) => handleChange(e.target.name, e.target.value)}
                    className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
                <span className='text-sm text-gray-500'>This is the unique URL of this page, displayed below the post title in the search results.</span>
            </div>

            <div className="mb-4 py-4 px-4 rounded-md bg-gray-100">
                <label className="block text-lg font-medium text-gray-700" for="description">Tags </label>
                <textarea id="description" name='description' value={news.description} onChange={(e) => handleChange(e.target.name, e.target.value)} rows="4"
                    className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />

            </div>

            <div className='text-center mb-20'>
                <button onClick={submitNews} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update News</button>
            </div>

        </div>
    );
};

export default EditNews;
